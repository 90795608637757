const cookieScript = require("./cookie-banner");

var pages = document.querySelectorAll(".page"),
  book = document.querySelector(".book"),
  rotate = document.querySelector("#rotate"),
  plug = document.querySelector("#plug");
for (var i = 0; i < pages.length; i++) {
  var page = pages[i];
  if (i % 2 === 0) {
    page.style.zIndex = pages.length - i;
  }
}

document.addEventListener("DOMContentLoaded", function () {
  if (document.documentElement.dataset.page === "reader") {
    for (var i = 0; i < pages.length; i++) {
      pages[i].pageNum = i + 1;
      pages[i].onclick = function () {
        switch (this.pageNum) {
          case 1:
            book.classList.toggle("open");
            break;
          //
          case 2:
            book.classList.toggle("open");
            break;
          //
          case 19:
            book.classList.toggle("closed");
            plug.classList.toggle("hidden");
            break;
          //
          case 20:
            book.classList.toggle("closed");
            break;
          //
          default:
          //do nothing
        }
        if (!book.classList.contains("flipping")) {
          if (this.pageNum % 2 === 0) {
            this.classList.remove("flipped");
            this.previousElementSibling.classList.remove("flipped");
          } else {
            this.classList.add("flipped");
            this.nextElementSibling.classList.add("flipped");
          }
          book.classList.add("flipping");
          setTimeout(function () {
            book.classList.remove("flipping");
          }, 1350);
        }
      };
    }

    document.querySelector("#plugClose").onclick = function () {
      plug.classList.add("hidden");
    };
    document.querySelector("#plugBg").onclick = function () {
      plug.classList.add("hidden");
    };
    document.querySelector("#rotateClose").onclick = function () {
      rotate.classList.remove("port:block");
    };
    document.querySelector("#rotateBg").onclick = function () {
      rotate.classList.remove("port:block");
    };
    document.querySelector("#helpToggle").onclick = function () {
      book.classList.toggle("help");
      document.querySelectorAll("#helpToggle svg").forEach((icon) => {
        icon.classList.toggle("hidden");
      });
    };
    document.querySelector("#screenToggle").onclick = function () {
      toggleFullScreen();
      document.querySelectorAll("#screenToggle svg").forEach((icon) => {
        icon.classList.toggle("hidden");
      });
    };
    document.querySelector("#bookRepeat").onclick = function () {
      for (var i = 0; i < pages.length; i++) {
        pages[i].classList.remove("flipped");
      }
      book.classList.remove("open");
      book.classList.remove("closed");
      plug.classList.add("hidden");
    };
    if (document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled) {
      document.querySelector("#screenToggle").classList.remove("hidden");
    } else {
      document.querySelector("#screenToggle").classList.add("hidden");
    }

    let root = document.documentElement;
    window.onresize = function () {
      root.style.setProperty("--real-vh", window.innerHeight + "px");
      root.style.setProperty("--real-vw", window.innerWidth + "px");
    };
    window.onresize(); // called to initially set the height.
  }

  function toggleFullScreen() {
    var elem = document.body;
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        document.body.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        document.body.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        document.body.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
});
