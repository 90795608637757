// Creare's 'Implied Consent' EU Cookie Law Banner v:2.4
// Conceived by Robert Kent, James Bavington & Tom Foyster
// Put into a namespace and by Bjørn Rosell
//   Also changed behaviour so you have to click accept to make the banner stay away.
//   To make it behave like the original, set "createCookieWhenBannerIsShown" to true.

var CookieBanner = (function () {
  return {
    createCookieWhenBannerIsShown: false,
    createCookieWhenAcceptIsClicked: true,
    cookieDuration: 14, // Number of days before the cookie expires, and the banner reappears
    cookieName: "cookieConsent", // Name of our cookie
    cookieValue: "accepted", // Value of cookie

    _createDiv: function (html) {
      var bodytag = document.getElementsByTagName("body")[0];
      var div = document.createElement("div");
      div.setAttribute("id", "cookie-law");
      div.innerHTML = html;

      bodytag.appendChild(div); // Adds the Cookie Law Banner just before the closing </body> tag
      // or
      //   bodytag.insertBefore(div, bodytag.firstChild); // Adds the Cookie Law Banner just after the opening <body> tag

      document.getElementsByTagName("body")[0].className += " cookiebanner"; //Adds a class tothe <body> tag when the banner is visible

      if (CookieBanner.createCookieWhenBannerIsShown) {
        CookieBanner.createAcceptCookie();
      }
    },

    _createCookie: function (name, value, days) {
      var expires;
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },

    _checkCookie: function (name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    _eraseCookie: function (name) {
      CookieBanner._createCookie(name, "", -1);
    },

    createAcceptCookie: function () {
      CookieBanner._createCookie(CookieBanner.cookieName, CookieBanner.cookieValue, CookieBanner.cookieDuration); // Create the cookie
    },

    closeBanner: function () {
      var element = document.getElementById("cookie-law");
      element.parentNode.removeChild(element);
      document.getElementsByTagName("body")[0].classList.remove("cookiebanner");
    },

    accept: function () {
      CookieBanner.createAcceptCookie();
      CookieBanner.closeBanner();
    },

    showUnlessAccepted: function (html) {
      //   alert(CookieBanner._checkCookie(CookieBanner.cookieName));
      //   alert(document.cookie);
      document.getElementsByTagName("body")[0].classList.remove("cookiebanner");
      if (CookieBanner._checkCookie(CookieBanner.cookieName) != CookieBanner.cookieValue) {
        CookieBanner._createDiv(html);
      }
    },
  };
})();

window.onload = function () {
  var html = `<div class="fixed left-0 bottom-0 z-40">
        <div
            class="fixed sm:right-4 bottom-20 rounded-lg bg-white shadow-2xl w-full sm:w-1/2 xl:w-1/4 max-w-[450px] overflow-hidden">
            <div class="">
                <div class="relative overflow-hidden px-8 pt-8">
                    <div class="text-2xl flex flex-col pb-4">
                        <small class="text-sm sm:text-base font-sans font-semibold leading-7 text-thunderbird-700">Hallo
                            Besucher..</small>
                        <span class="text-3xl font-bold">Wir sind die Cookies!</span>
                    </div>
                    <div class="pb-4">
                        <p>
                            Diese Webseite speichert ausschließlich Funktionale Cookies (First Party
                            Cookies). Die in diesen Cookies gespeicherte
                            Daten werden ausschließlich an diese Webseite gesendet und nicht mit Dritten geteilt.
                        </p>
                    </div>
                </div>
            </div>
            <div class="w-full flex justify-center items-center border-t border-solid border-mercury-200">
                <button id="closeCookieBanner" class="flex-1 px-4 py-3 text-mercury-700 hover:text-white hover:bg-success duration-150">
                    Verstanden!
                </button>
            </div>
        </div>
    </div>`;

  CookieBanner.showUnlessAccepted(html);

  var closeButton = document.getElementById("closeCookieBanner");

  if (closeButton) {
    //element DOES exists
    closeButton.addEventListener("click", function () {
      CookieBanner.accept();
    });
  }
};
